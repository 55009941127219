@mixin wysiwyg {
  color: var(--color-grey-medium);

  h1, h2, h3, h4, h5, h6 {
    color: $dark;

    // &:not([class]) {
    //   margin: oldGutters(1) 0;

    //   &:first-child {
    //     margin-top: 0;
    //   }
    // }
  }

  a {
    color: var(--color);
    text-decoration: underline;
  }

  b, strong {
    color: var(--color);
    font-weight: normal;
  }

  ul, ol {
    padding-left: rem(40);
  }

  ul > li {
    list-style-type: disc;
  }

  ol > li {
    list-style-type: decimal;
  }

  hr {
    height: 1px;
    border: none;
    background-color: var(--color);
  }

  table {
    height: auto;
    border: none;
    margin-right: auto;
    margin-left: auto;
    border-collapse: separate;
    border-spacing: 0;

    td, th {
      min-height: oldGutters(1);
      box-sizing: border-box;
      padding: rem(10) rem(20);
      border: 0;

      // text-align: center;

      // &:not(:first-of-type) { text-align: center; }
      &:not(:last-of-type) { border-right: 1px solid $border-color; }

      &:first-of-type {
        // min-width: rem(105);
      }
    }

    td {
      border-top: 1px solid $border-color;
    }
  }

  @include tablet-portrait {
    h1, h2, h3, h4, h5, h6 {
      // &:not([class]) {
      //   margin: oldGutters(1) 0;

      //   &:first-child {
      //     margin-top: 0;
      //   }
      // }
    }

    table {
      td, th {
        min-height: oldGuttersM(1);
        padding: rem(8) oldGuttersM(1);
      }
    }

    ul {
      padding-left: rem(40);
    }
  }
}
