.radio, .checkbox {
  display: flex;
  position: relative;

  &.radio {
    color: var(--color-grey-medium);

    & + & {
      margin-left: rem(40);
    }
  }

  &.checkbox {
    .checkbox__label {
      margin-left: var(--padding-s);
    }

    & + & {
    }
  }

  &__input {
    opacity: 0;
    cursor: pointer;
    @include fullscreen;



    &:checked ~ .radio__box::after, &:checked ~ .checkbox__box::after {
      display: block;
    }

    &:checked ~ .radio__label, &:checked ~ .checkbox__label {
      color: var(--color-main);
    }

    &.error ~ .radio__label, &.error ~ .checkbox__label {
      color: $error !important;
    }
  }

  &__label {
    margin-left: rem(10);
    display: inline-flex;
    @include style(typo-base-uppercase);
  }

  &__box {
    width: rem(10);
    height: rem(10);
    margin-top: rem(2);
    border: 1px solid var(--color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    &::after {
      content: '';
      background: var(--color);
      display: none;
      height: rem(6);
      width: rem(6);
    }
  }
}

@include tablet-portrait {
  .radio, .checkbox {
    align-items: flex-start;

    &__box {
      margin-top: rem(4);
    }
  }

  }
