.native-select {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-xxs);

  --error-height: #{rem(14)};

  .field__error {
    width: 100%;
  }

  &__label {
    @include style(typo-base-uppercase);
    position: relative;
    display: block;
    width: 100%;
    padding: var(--padding-s) 0;
    border: 0;
    border-radius: 0;
    border-bottom: var(--border-size) solid var(--color-grey-divider-light);
    appearance: none;
    background: url("../icons/chevrons/chevron-down.svg") no-repeat right;
    background-position: center right;
    background-size: contain;
    color: var(--color-grey-medium);
    text-transform: uppercase;
    transition: border-color var(--opacity-duration) ease-in-out;

    &:focus-visible {
      outline: none;
    }
  }

  &:hover{
    .native-select__label {
      border-color: var(--color-main);
    }
  }

  &.filled {
    select {
      color: var(--color-main);
    }
  }

  &__inner {
    width: 100%;


  }

  &__tag {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    color: var(--color);
    cursor: pointer;
    opacity: 0;
    outline: none !important;
    text-transform: uppercase;

  }
}

