.category {
  padding: 0;


  &__wrapper {
    position: relative;
    min-height: var(--ivh);


    // transform: translateZ(0);
  }

  &__inner {
  }

  &__list-empty {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--grid-gutter);
    padding: var(--padding-m);
    @include style(typo-base-body);
    margin-top: var(--gap-xxl);

    .button {
      width: grid(4);
    }

  }

  &__list {
    @extend .product-list;
    max-width: var(--vw);
    padding: 0;
  }


  &__product {

    @include not-tablet-portrait {
      .reveal-list & {
      }
    }

    @include not-tablet-portrait {
      &.full-width {

      }
    }

    .appearing &:not(:nth-child(-n+12)) { //show only X first during transition
      display: none;
    }
  }

  &__text {

  }

  &__next {
    &-subtitle {
    }

    &-title-wrapper {
    }

    &-title {
    }

    &-inner {
    }

    &-img {
    }
  }

  @include tablet-portrait {
    &__list-empty {
      margin-top: var(--gap-xl);

      .button {
        width:100%;
      }

    }
  }
}
