.home-diptych {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;


  &__track {
    position: sticky;
    z-index: 2;
    top: var(--page-top);
    bottom: rem(200);
    left: rem(20);
    display: none;
    width: calc(100% - rem(40));
    margin-top: rem(20);
    background-color: rgb(249 249 245 / 30%);
    transition: opacity 0.2s ease-in-out;

    &.is-hidden {
      opacity: 0;
    }

    &--wrapper {
      height: 100%;
    }

    &-inner {
      position: relative;
      width: var(--track-width);
      height: rem(2.5);
      background-color: var(--color-white);
    }
  }

  &__inner {
    position: relative;
    display: flex;
    width: 100%;

    & > * {
      flex: 0 0 50%;
    }
  }

  .home-block-content__title {
    line-height: 1em;
  }
}

@include tablet-portrait {
  .home-diptych {
    // .home__blocks & {
    //   scroll-snap-align: none;
    // }

    &__inner {
      flex-direction: column;

      & > * {
        flex-basis: var(--ivh) !important;

        //   scroll-snap-align: start;
      }
    }

    &.carousel {
      flex-direction: column;
    }

    &.carousel & {
      &__track {
        display: block;

        .header__light & {
          background-color: rgb(0 0 0 / 10%);

          &-inner {
            background-color: var(--color-main);
         }
        }


      }

      &__inner {
        overflow: scroll hidden;
        flex-direction: row;
        margin-top: rem(-22);
        scroll-snap-type: x mandatory;
        @include hide-scrollbar;




        & section {
          scroll-snap-align: start;
        }

        & > * {
          flex-basis: 100% !important;
        }

        .home-block-content__container {
          width: 100%;
        }

        .home-block-content__inner {
          position: absolute;
          top: var(--scroll);
          left: 0;
          width: 100%;
          height: max-content;
          transform: translateY(-100%);
          transition: top 0.06s ease-out;
        }
      }
    }
  }
}
