.field {
  position: relative;

  &__label {
    display: none;
  }

  &__error {
    height: max(var(--error-height, rem(16)), rem(16));
    margin-top: rem(4);
    color: var(--color-red);
    @include style(typo-footnote);

    &::before {
      content: var(--form-error);
    }
  }

    &__show {
    position: absolute;
    top: 50%;
    right: 0;
    color: var(--color-grey-medium);
    transform: translateY(-100%);
    transition: color var(--opacity-duration) ease-in-out;
    @include style(typo-base-uppercase);

    @include hover {
      &:hover {
        color: var(--color-main)
      }
    }
  }

  .field__input {
    width: 100%;
    border:0;
    border-radius: 0;
    appearance: none;
    background-color: transparent;
    font-family: $main-font;
    @include style(typo-base-uppercase);

    &[data-validation="email"] {
      text-transform: unset;

    }

    &::placeholder {
      text-transform: uppercase;
      transition: color var(--opacity-duration) ease-in-out;
    }

    &:focus {
      &::placeholder {
        opacity: 0.5;
      }
    }

  }

  &__small {
    width: 100%;

    .field__inner {
      border-bottom: var(--border-size) solid var(--color-grey-divider-light);
      scroll-margin-top: rem(40);
      scroll-padding-top: rem(40);
      transition: border-color .3s ease-in-out;
    }

    &.field__is--focused {
      .field__inner {
        border-bottom-color: var(--color-main);
      }
    }

    &.error {
      .field__inner {
        border-bottom-color: var(--color-red);
      }
    }

    @include hover {
      &:hover {
        .field__inner {
          border-bottom-color: var(--color-main);
        }
      }
    }

    .field__input {
      padding: 0;
      padding: var(--padding-s) 0;

      &::placeholder {
        color: var(--color-grey-medium);
      }
    }
  }

  &__medium {
    .field__inner {
      display: flex;
      overflow: hidden;
      height: rem(38);
      align-items: center;
      justify-content: center;
      border-radius: rem(4);
      border-bottom: 0;
      background-color: var(--color-grey-light);
      color: var(--color-main);
      transition: background-color var(--opacity-duration) ease-in-out;
    }

    .field__input {
      padding: rem(12);
    }

    &.field__with-icon {
      .field__input {
        padding: rem(10.5);
        padding-left: rem(49);
      }
    }
  }


  &__large {
    .field__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: rem(4);
      border-bottom: 0;
      background-color: var(--color-grey-light);
      color: var(--color-main);
      transition: background-color var(--opacity-duration) ease-in-out;
    }

    .field__error {
      height: rem(20);
      padding-top: rem(5);
      margin-bottom: rem(5);
    }

    .field__input {
      padding: rem(20);
    }

    &.field__with-icon {
      .field__input {
        height: rem(58);
        padding: rem(12);
        padding-left: rem(49);
      }
    }

    @include hover {
      &:hover {
        .field__inner {
          background-color: #F2F2F2;
        }
      }
    }
  }



  @include tablet-portrait {
    // overflow: hidden;

    &__small {
      .field__inner {
        height: rem(36);

        input {
          padding: rem(7.75) 0;
        }
      }
    }

    &__large {
      .field__inner {
        height: rem(56);
      }
    }

    .field__input {
      width: 100%;
      align-self: stretch;
      font-size: rem(16);
      transform: scale(0.875);
      transform-origin: center left;
      @include touch {
        &[type="password"] {
          font-family: PasswordDots, sans-serif;

          &::placeholder {
            font-family: $main-fontname;
            font-size: rem(16);
            font-variant: normal;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 100%;
            text-transform: uppercase;
          }
          line-height: 16.8px;
        }
      }
    }


  }
}
