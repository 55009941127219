@import "/home/lapoz/Work/web/fursac/src/styles/libs/_old_variables.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_variables.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_icons.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_zindex.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_z-index.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_wysiwyg.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_rem.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_mq.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_mixins.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_grid.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_functions.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_fonts.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_font-name.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_easing.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_styling.scss"; 



// TODO : À RETIRER AVANT LA MOSE EN PROD
#modal-container {
  display: none !important;
}

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  overflow-x: none;

  // scroll-behavior: smooth;
}




html {
  position: relative;
  overflow: visible;
  height: 100%;
  min-height: 100%;
  font-family: HelveticaNowDisplay, sans-serif;

  &.fursac {
    font-size: var(--rem) !important;
  }
  overscroll-behavior: none;

  &.safari.desktop {
    overscroll-behavior: auto;
  }

  @include variables;

  --vh: 100vw;
  --rem: #{$rem-reference};
  --rem-clamp: 1px;
  --header-top: 0;
  --page-top: 0;
  --color: #{$black};
  --background: #{$background};
  --stagger-delay: 0s;

  &.safari  {
    &.mobile {
      --border-size: #{rem(0.8)};
    }
  }

  // letter-spacing: .1em;

  &.no-desktop.no-scroll {
    &, body {
      overflow: hidden;
      height: var(--vh);
      -ms-touch-action: none;
      touch-action: none;
    }
  }

  &.no-scroll-safari {
    transform: scale(0);
    transition: transform 0.3s ease-out;
  }

  &.desktop.no-scroll {
    body {
      overflow: hidden;
      height: 100%;
    }
  }

  // @include medium {
  //   font-size: $rem-reference * .85;
  // }
  // @include small {
  //   font-size: $rem-reference * .75;
  // }

  // @include tablet {
  //   font-size: $rem-reference * .65;
  // }

  // @include tablet-portrait {
  //   font-size: $rem-reference;
  // }

  &.loading {
    cursor: wait !important;

    * {
      pointer-events: none !important;
    }
  }
}

.scrolling-element {
  overflow: hidden scroll;

  // &.snap {
  //   scroll-snap-type: y mandatory;
  // }
}

body {
  --background-color: #{$white};
  position: relative;
  max-width: 100%;
  min-height: 100%;
  background: var(--background-color);
  font-size: rem(12);

  &[style^="overflow"] { /* Override socloz style */
    overflow: visible!important;
  }


  > aside.env {
    position: fixed;
    z-index: 9000;
    top: 0;
    right: 0;
    left: 0;
    height: 2px;

    &.dev, &.preprod { background: #007f64; }
    &.staging { background: #ff851b; }
  }
}

* {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
}

h1, h2, h3, h4, h5, h6 {
  // font-weight: normal;
  margin: 0;
  font-size: inherit;
  br { margin: 0 !important; }
}

button, a {
  color: inherit;
  cursor: pointer;
  font-weight: inherit;
  text-decoration: none;
}

input, button, a {
  &:focus, &:active {
    outline: none;
  }
}

strong {
  font-weight: bold;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}

img {
  display: block;
}

button, input[type='submit'] {
  display: block;
  padding: 0;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
}

button, input {
  font-size: inherit;
  text-transform: inherit;
}

.hidden {
  position: fixed;
  left: -100vh;
  pointer-events: none;
  visibility: hidden;
}

.grid-helper {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  display: none;
  width: var(--vw);
  height: 100vh;
  padding: 0 var(--grid-margin);
  gap: var(--grid-gutter);
  grid-template-columns: repeat(12, 1fr);
  pointer-events: none;

  &-col {
    background: red;
    opacity: 0.1;
  }

  &.visible {
    display: grid;
  }
  @include tablet-portrait {
    grid-template-columns: repeat(6, 1fr);

    &-col:nth-child(n + 7) {
      display: none;
    }
  }
}

.icon {
  @include icons;
}

.no-select {
  user-select: none;

  * {
    user-select: none;
  }
}

.ignored-link {
  pointer-events: none !important;
}


@import 'fonts/fonts';
@import 'layout/_buttons.scss';
@import 'layout/_content-page.scss';
@import 'layout/_error-page.scss';
@import 'layout/_misc.scss';
@import 'layout/_page-tabs.scss';
@import 'layout/_page.scss';
@import 'layout/_redirect-page.scss';
@import 'layout/_styling.scss';
@import 'layout/_transversal.scss';
@import 'modules/_account-item.scss';
@import 'modules/_address-label.scss';
@import 'modules/_blueshift-popin.scss';
@import 'modules/_breadcrumbs.scss';
@import 'modules/_didomi.scss';
@import 'modules/_drawer.scss';
@import 'modules/_editorial-subnav.scss';
@import 'modules/_filter-bar-item.scss';
@import 'modules/_filter-bar.scss';
@import 'modules/_footer.scss';
@import 'modules/_header.scss';
@import 'modules/_infinite-carousel.scss';
@import 'modules/_infinite-line.scss';
@import 'modules/_loading-bar.scss';
@import 'modules/_mask-reveal.scss';
@import 'modules/_menu.scss';
@import 'modules/_notification.scss';
@import 'modules/_overlay.scss';
@import 'modules/_pagination.scss';
@import 'modules/_panel.scss';
@import 'modules/_parallax-block.scss';
@import 'modules/_parallax-image.scss';
@import 'modules/_popin.scss';
@import 'modules/_recap.scss';
@import 'modules/_reveal.scss';
@import 'modules/_search-panel.scss';
@import 'modules/_seo-links.scss';
@import 'modules/_switch-button.scss';
@import 'modules/_webstore-switcher.scss';
@import 'modules/_xlinking.scss';
@import 'components/_accordion.scss';
@import 'components/_add-to-wishlist.scss';
@import 'components/_address-form.scss';
@import 'components/_button.scss';
@import 'components/_catalog-footer.scss';
@import 'components/_catalog-push.scss';
@import 'components/_categories-list.scss';
@import 'components/_category-card.scss';
@import 'components/_close_button.scss';
@import 'components/_drag-controller.scss';
@import 'components/_drawer-panels.scss';
@import 'components/_editorial-related-content.scss';
@import 'components/_error.scss';
@import 'components/_facets.scss';
@import 'components/_field.scss';
@import 'components/_filters.scss';
@import 'components/_footer-links.scss';
@import 'components/_form.scss';
@import 'components/_home-product-card.scss';
@import 'components/_map.scss';
@import 'components/_native-select.scss';
@import 'components/_page-header.scss';
@import 'components/_player.scss';
@import 'components/_price.scss';
@import 'components/_product-card.scss';
@import 'components/_quick-buy.scss';
@import 'components/_radio-checkbox.scss';
@import 'components/_radio-group.scss';
@import 'components/_scroll-indicator.scss';
@import 'components/_select.scss';
@import 'components/_submit.scss';
@import 'components/_success.scss';
@import 'components/_tabs.scss';
@import 'components/_text-button.scss';
@import 'components/_text-cta.scss';
@import 'components/_toaster.scss';
@import 'sections/_faq.scss';
@import 'sections/advices/_definitions.scss';
@import 'sections/brand/_brand.scss';
@import 'sections/brand/partials/_brand-diptych-image-text.scss';
@import 'sections/brand/partials/_brand-title.scss';
@import 'sections/catalog/_category.scss';
@import 'sections/catalog/_product.scss';
@import 'sections/catalog/partials/_color-selector.scss';
@import 'sections/catalog/partials/_product-bar.scss';
@import 'sections/catalog/partials/_product-colors.scss';
@import 'sections/catalog/partials/_product-details.scss';
@import 'sections/catalog/partials/_product-fairlymade.scss';
@import 'sections/catalog/partials/_product-focus.scss';
@import 'sections/catalog/partials/_product-gallery.scss';
@import 'sections/catalog/partials/_product-infos.scss';
@import 'sections/catalog/partials/_product-list.scss';
@import 'sections/catalog/partials/_product-related.scss';
@import 'sections/catalog/partials/_product-sizes.scss';
@import 'sections/catalog/partials/_product-zoom.scss';
@import 'sections/catalog/partials/_shop-look.scss';
@import 'sections/catalog/partials/_size-selector.scss';
@import 'sections/checkout/_adyen.scss';
@import 'sections/checkout/_cart.scss';
@import 'sections/checkout/_checkout.scss';
@import 'sections/checkout/_failed.scss';
@import 'sections/checkout/_order.scss';
@import 'sections/checkout/_payment.scss';
@import 'sections/checkout/_pending.scss';
@import 'sections/checkout/_personal-details.scss';
@import 'sections/checkout/_pickup.scss';
@import 'sections/checkout/_shipping.scss';
@import 'sections/checkout/_success.scss';
@import 'sections/checkout/partials/_checkout-breadcrumb.scss';
@import 'sections/checkout/partials/_checkout-footer.scss';
@import 'sections/checkout/partials/_checkout-popin.scss';
@import 'sections/checkout/partials/_checkout-promo.scss';
@import 'sections/checkout/partials/_checkout-summary.scss';
@import 'sections/checkout/partials/_checkout-total.scss';
@import 'sections/checkout/partials/_row-product-card.scss';
@import 'sections/collections/_collection.scss';
@import 'sections/collections/_collections.scss';
@import 'sections/collections/partials/_collection-popin.scss';
@import 'sections/collections/partials/_list-block-content.scss';
@import 'sections/contact/_contact.scss';
@import 'sections/customer/_address.scss';
@import 'sections/customer/_addresses.scss';
@import 'sections/customer/_alert.scss';
@import 'sections/customer/_auth.scss';
@import 'sections/customer/_login.scss';
@import 'sections/customer/_orders.scss';
@import 'sections/customer/_payment.scss';
@import 'sections/customer/_profile.scss';
@import 'sections/customer/_register.scss';
@import 'sections/customer/_wishlist.scss';
@import 'sections/customer/partials/_card-block.scss';
@import 'sections/customer/partials/_order-block.scss';
@import 'sections/customer/partials/_order-tracking.scss';
@import 'sections/customer/partials/_profile-form.scss';
@import 'sections/customer/partials/_social-login.scss';
@import 'sections/customer/partials/_wishlist-header.scss';
@import 'sections/customer/partials/_wishlist-product.scss';
@import 'sections/editorial/_editorial.scss';
@import 'sections/editorial/partials/_image-zoom.scss';
@import 'sections/error/_404.scss';
@import 'sections/home/_home_links.scss';
@import 'sections/home/_home-block-content.scss';
@import 'sections/home/_home-category.scss';
@import 'sections/home/_home-diptych-products.scss';
@import 'sections/home/_home-diptych.scss';
@import 'sections/home/_home-media.scss';
@import 'sections/home/_home-products.scss';
@import 'sections/home/_home-search.scss';
@import 'sections/home/_home.scss';
@import 'sections/home/_menu.scss';
@import 'sections/home/_sizes.scss';
@import 'sections/info/_legals.scss';
@import 'sections/info/_sitemap.scss';
@import 'sections/journal/_article.scss';
@import 'sections/journal/partials/_article-carousel.scss';
@import 'sections/journal/partials/_article-gallery.scss';
@import 'sections/journal/partials/_article-image.scss';
@import 'sections/journal/partials/_article-products.scss';
@import 'sections/journal/partials/_article-text-image.scss';
@import 'sections/journal/partials/_article-text.scss';
@import 'sections/journal/partials/_article-video.scss';
@import 'sections/journal/partials/_journal-card.scss';
@import 'sections/search/_search-results.scss';
@import 'sections/search/_search.scss';
@import 'sections/stores/_stores.scss';


