.home-media {
  --min-height: 120vh;
  height: auto;
  position: relative;
  width: 100%;

  &__inner {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
    width: 100%;
    height: 100%;
  }

  &__media {
    object-fit: cover;
    width: grid(8, 1);
    height: auto;
    min-height: var(--min-height);
    justify-self: flex-end;
  }

  &:not(.margin) & {
    &__media {
      object-fit: cover;
      width: 100%;
      max-width: none;
      height: 100%;
    }
  }
}

@include tablet-portrait {
  .home-media {
    --min-height: calc(100vh + var(--notification-height));

    &__inner {
      padding: 0 gridM(0, 1);
    }

    &__media {
      width: 100%;
      height: 100%;
    }

    &:not(.margin) & {
      &__inner {
        padding: 0;
      }

      &__media {
        height: 100%;
      }
    }
  }
}
