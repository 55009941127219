.product-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-auto-rows: auto;
  box-sizing: border-box;
  overflow: hidden;
  @include small {
    grid-template-columns: repeat(3, 1fr);
  }
  @include tablet-portrait {
    grid-template-columns: repeat(2, 50%);
  }
}

