@use 'sass:math';

.product-card {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;


  &__tag {
    position: absolute;
    z-index: 2;
    top: rem(14);
    left: rem(14);
    padding: rem(3) rem(6) rem(4);
    background-color: var(--color-grey-divider-light);
    font-size: rem(10);
    font-weight: bold;
    line-height: 120%;
    text-transform: uppercase;
  }

  &__navigation {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    justify-content: space-between;
    padding: 0;
    aspect-ratio: #{math.div(360,500)};
    gap: var(--gap-s);
    pointer-events: none;

    button {
      height: 100%;
      padding: 0 calc(var(--padding-s));
      border: none;
      cursor: pointer;
      pointer-events: all;

      &.disabled {
        opacity: 0.2;
      }
    }
  }

  &__images {
    position: relative;
    display: flex;
    overflow: scroll hidden;
    width: calc(100%);
    flex-wrap: nowrap;
    aspect-ratio: #{math.div(360,500)};
    scroll-snap-type: x mandatory;
    @include hide-scrollbar;


    &.switching {
      scroll-snap-type: none;
    }
  }

  &__track {
    position: absolute;
    bottom: rem(0);
    left: 0;
    display: none;
    width: 100%;
    height: rem(2);
    background-color: var(--color-grey-divider-light);

    &-inner {
      width: var(--track-width);
      height: 100%;
      background-color: var(--color-main);
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    scroll-snap-align: start;

    img, video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
  }

  &__infos {
    position: relative;
    display: flex;
    height: var(--product-card-infos-height);
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 1;
    padding: var(--padding-s) var(--padding-m) var(--padding-m);
    gap: var(--grid-gutter);

    &-content {
      position: relative;
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: var(--padding-m);
    }

    &-title-variants {
      width: 100%;
    }
  }



  &__title {
    display: block;
    width: 70%;
  }

  &__variants {
    display: none;
    width: 100%;
    flex-direction: column;
    gap: var(--gap-s);
  }

  &__sizes {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    @include style(typo-base-uppercase);
  }

  &__size {
    &.oos {
      color: var(--color-grey-medium);
    }

    &:not(:last-child) {
      margin-right: var(--gap-s);
    }
  }

  &__colors {
    display: flex;
    max-width: grid(1, 3.5);
    gap: var(--gap-xxs);
  }

  &__color-pattern {
    display: flex;
    width: max-content;
    align-items: center;
    gap: var(--gap-s);
    @include style(typo-base-uppercase);

    div {
      flex-shrink: 0;
    }

    span {
      display: block;
      overflow: hidden;
      width: grid(1, 2);
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__color {

   @include style(color-swatch)
  }

  &__toggle-wishlist {
    position: absolute;
    z-index: 4;
    top: var(--padding-s);
    right: var(--padding-s);
    display: none;
    width: max-content;
    padding: 0;
    cursor: pointer;

    .icon__wishlist {
      &-active {
        display: none;
      }
    }

    &.active {
      .icon__wishlist {
        &-active {
          display: block;
        }
          display: none;
      }
    }
  }


  &__wishlist, &__alert {
    .product-card__title {
    @include style(typo-base-uppercase);

    }

    .product-card__price {
      @include style(typo-base-uppercase);

    }

    .product-card__toggle-wishlist {
      display: block;
    }

    .product-card__color-pattern span {
      width: auto;
    }



    .product-card__infos {
      height: auto;
      justify-content: space-between;

      &-title-variants {
        display: flex;
        flex-direction: column;
        gap: var(--gap-s);
      }
    }

    .product-card__colors {
      max-width: grid(2,1);
    }

    .product-card__sizes {
      width: auto;
    }

    .product-card__variants {
      position: relative;
      display: flex;
      width: 100%;
      flex-flow: row wrap;
      color: var(--color-grey-medium);
      gap: rem(6) rem(16);
      @include style(typo-base-uppercase);
    }

    @include hover {
      @include not-tablet-portrait {
        &:hover {
          .product-card__navigation {
            display: flex;
          }
        }
      }
    }

  }

  &__list{
    scroll-margin-top: calc(rem(85) + rem(63));

    .product-card__title {
    @include style(typo-base-uppercase-small-mobile);

    }

    .product-card__price {
      @include style(typo-base-uppercase-small-mobile);

    }

    .product-card__images {
      aspect-ratio: #{math.div(196.5,270)};
    }

    .product-card__navigation {
      aspect-ratio: #{math.div(196.5,270)};
    }

    @include hover {
      @include not-tablet-portrait {
        &:hover {
          .product-card__navigation {
            display: flex;
          }

          .product-card__title {
            display: none;
          }

          .product-card__variants {
            display: flex;
          }

          .product-card__toggle-wishlist {
            display: block;
          }
        }
      }
    }
  }

  &__related {
    .product-card__title {
      width: 100%;
      @include style(typo-base-uppercase-small-mobile);
      @include ellipsis(2, 120%);

    }

    .product-card__price {
      @include style(typo-base-uppercase-small-mobile);

    }

    .product-card__infos {
      height: auto;
      padding: var(--padding-s) var(--padding-m) var(--padding-xl);

      &-content {
        flex-direction: column;
        gap: var(--gap-xxxs);

        .price {
          flex-direction: row;
          align-items: flex-start;
          gap: var(--gap-s);

        }
      }
    }

    .product-card__sizes {
      @include ellipsis(2, 120%);
    }

    @include hover {
      @include not-tablet-portrait {
        &:hover {
          .product-card__navigation {
            display: flex;
          }

          .product-card__title {
            display: none;
          }

          .product-card__price {
            display: none;
          }

          .product-card__variants {
            display: flex;
          }

          .product-card__toggle-wishlist {
            display: block;
          }
        }
      }
    }
  }

  &__color-selector {
    .product-card__title {
      width: 100%;
      @include style(typo-base-uppercase-small-mobile);
      @include ellipsis(2, 120%);
    }
          position: relative;

          &::after {
            position: absolute;
            z-index: 1;
            top: var(--border-size);
            left: var(--border-size);
            width: calc(100% - calc(var(--border-size) * 2));
            height: auto;
            aspect-ratio: #{math.div(360,500)};
            content: '';
            opacity: 0;
            outline: var(--border-size) solid var(--color-main);
            pointer-events: none;
            transition: opacity var(--opacity-duration) ease-in-out;
          }

        &.current {
          &::after {
            opacity: 1;
          }
        }

      &.hovered {
        &::after {
          opacity: 1;
        }
      }



    .product-card__price {
      @include style(typo-base-uppercase-small-mobile);

    }

    .product-card__infos {
      height: auto;
      padding: 0;
      padding-top: var(--padding-s);

    }

    @include hover {
      @include not-tablet-portrait {
        &:hover {
          .product-card__title {
            display: none;
          }

          .product-card__variants {
            display: flex;
          }

          .product-card__navigation {
            display: flex;
          }

          .product-card__price {
            visibility: hidden;
          }
        }
      }
    }
  }

  @include tablet-portrait {
    &__infos {
      flex-direction: column;
      justify-content: flex-start;

    }

    &__title {
      display: block;
      width: 100%;
      @include ellipsis(2,120%);
    }

    &__navigation {
      display: flex;

      button {
        display: none;
      }
    }

    &__track {
    display: block;
    }

    &__related {
      .product-card__infos {
        height: auto;
        padding: rem(15) var(--padding-m) var(--padding-l) var(--padding-m);
        gap: var(--gap-xxxs);
      }


      .product-card__navigation {
        display: none;
      }

      .product-card__track {
      display: none;
      }

      .product-card__tag {
        display: none;
      }

      .product-card__infos-title-variants {
        display: none;
      }

      .product-card__title, .product-card__price {
        align-self: center;
        font-size: rem(12);



        .price__discount {
          display: none;
        }
      }
    }

    &__wishlist, &__alert {
        .product-card__infos {
          height: auto;
        padding-top: rem(15);

        &-title-variants {
          gap: rem(8);
        }
        }

        .product-card__variants {
          width: 100%;
        }


    }

    &__list {
  .product-card__toggle-wishlist {
    display: block;
  }

      .product-card__infos {
        height:auto;
        padding-top: rem(15);
        padding-bottom: var(--padding-l);
      }

      .product-card__infos-content {
        flex-direction: column;
        gap: var(--gap-xxxs);

        .price {
          align-items: flex-start;
        }
      }
    }

    &__color-selector {
      .product-card {
        &__navigation {
          display: none;
        }

        &__tag {
          display: none;
        }



        &__track {
          display: none;
        }

        &__infos {
          flex-grow: 0;

          &-content {
            flex-direction: column;
            gap: var(--gap-xxxs);
          }
        }

        &__price {
          .price {
            align-items: flex-start;
          }
        }
      }
    }
  }
}
