.row-product-card {
  display: flex;
  width: 100%;
  padding: var(--padding-m) 0;
  gap: var(--grid-gutter);

  &__inner {
    position: relative;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    padding-top: rem(8);
  }

  &__content {
    display: flex;
    max-width: 75%;
    flex-direction: column;
    gap: rem(6);
  }

  &__price {
    max-width: 20%;
    @include style(typo-base-uppercase);

  }

  &__oos {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    .button {
      color: var(--color-main);
      @include style(typo-base-uppercase);
    }
  }



  &.oos & {
    &__img {
      opacity: .4;
    }

    &__top, &__types {
      color: $black;
      opacity: .12;
    }

    &__remove, &__alert {
      --color: #{$black};
    }
  }

  &__img {
    img, video {
      width: rem(88);
    }
  }

  &__top {
    display: flex;
    @include style(typo-base-uppercase);
    justify-content: space-between;
  }

  .add-to-wishlist {
    position: absolute;
    top: 0;
    right: calc(-1 * var(--padding-s));


  }

  &__bottom {
    @include style(typo-base-uppercase);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: var(--color-grey-medium);
  }

  &__discount {
    flex-basis: 100%;
    margin: 0 0 rem(10);
    color: $red;
  }

  &__discount + &__price .price {
    del, var {
      top: remMin(10);
    }
  }





  &__buttons {
    display: flex;
    width: 100%;
    flex-shrink: 0;
    justify-content: space-between;
    margin-top: rem(12);
    line-height: inherit;

    &.oos {
      flex-shrink: unset;
      justify-content: flex-end;
      margin-top: 0;
      color: var(--color-main);
    }
  }

  &__remove, &__oos {
    // flex-basis: 100%;
    // text-align: right;
  }


  &__types {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    gap: rem(16);
  }

  &__color {
    display: flex;
    flex-shrink: 1;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--gap-s);

    &-swatch {
      @include style(color-swatch)
    }

    span {
      max-width: rem(100);
      @include on-line-text-no-wrap;
    }
  }

  &__size {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__select {
    cursor: pointer;
    opacity: 0;
    @include fullscreen;
  }

  &__quantity {
    display: flex;
    width: max-content;
    min-width: rem(60);
    height: var(--padding-m);
    align-items: center;
    border-radius: rem(4);
    background-color: #f7f7f7;
    color: var(--color-main);

    button {
      width: rem(32);
      height: 100%;
      background-position: center;

      &:first-child {
        @extend .icon__minus-alt;
      }

      &:last-child {
        @extend .icon__plus-alt;
      }
    }

  }


  &__cart {
    .row-product-card {
      &__top {
        flex-direction: column;
        gap: rem(6);
      }

      &__types {
        margin-bottom: rem(-1);
      }

      &__inner {
        width: grid(4,-2.5);
      }

      &__price {
        max-width: unset;

        .price {
          flex-direction: row;
          align-items: flex-start;
          gap: var(--padding-s);
        }
      }

      &__content {
        width: 100%;
        max-width: unset;
        justify-content: space-between;
      }

      &__bottom {
        margin-bottom: var(--padding-s);
      }
    }
  }

  &__shop-the-look, &__look {
    &:not(:last-child) {
      border-bottom: rem(.5) solid var(--color-grey-divider-light);
    }
    margin: 0 !important;
    @include hover {
      &:hover {
        .row-product-card__see {
          opacity: 1;
        }
      }
    }

    .row-product-card {
      &__inner {
        justify-content: flex-start;
        padding: rem(8) 0;
        gap: rem(12);
      }

      &__see {
        position: absolute;
        bottom: 0;
        left: 0;
        color: var(--color-main);
        opacity: 0;
        transition: opacity var(--opacity-duration) ease-in-out;
      }



      &__content {
        width: 100%;
        max-width: unset;
        gap: rem(12);
      }

      &__top {
        flex-direction: column;
        gap: rem(6);
      }

      &__title {
        max-width: 80%;
      }

      &__price {
        max-width: unset;
        color: var(--color-grey-medium);


         .price  {
          flex-direction: row;
          align-items: flex-start;
          gap: rem(10);

          span {
          width: 100%;
          }
        }
      }

      &__color {
        gap: rem(4);
      }
    }
  }

  &__look {
    .row-product-card__color {
      span {
        max-width: calc(100% - rem(8) - rem(4));
      }
    }
  }

  &__checkout {
    .row-product-card {
      &__inner {
      }

      &__types {
        gap: rem(3) rem(20);
      }

      &__img {
        img {
          width: rem(59);
        }
      }

      &__wishlist {
        display: none;
      }

      &__quantity {
        background: transparent;
        color: var(--color-grey-medium);
      }


      &__top {
        flex-direction: column;
        justify-content: flex-start;
        @include style(typo-base-uppercase-small-mobile);
      }

      &__price {
        @include style(typo-base-uppercase-small-mobile);

        .price {
          gap: rem(6);
        }
      }

      &__bottom {
        @include style(typo-base-uppercase-small-mobile);
      }

      &__oos {
        display: flex;
        flex-direction: column;
        gap: rem(4);

        .button {
        @include style(typo-base-uppercase-small-mobile);

        }
      }
    }
  }

  @include not-tablet-portrait {
    &__checkout {
      .row-product-card {
        &__quantity {
          > span:last-of-type {
            display: none;
          }
        }
      }
    }
  }

  @include extra-small {
    &__checkout {
      .row-product-card {
       &__color > span {
        max-width: rem(50);
       }
      }
    }
  }

  @include tablet-portrait {
    &__img {
      img, video {
        width: rem(104);
      }
    }

    &__title {
      max-width: 100%;
      @include ellipsis(2, rem(16.8));
    }

    &__inner {
      width: grid(4);
    }

    &__color {
      width: grid(3);

      span {
        max-width: 100%;
      }
  }

  &__content {
    max-width: 70%;

  }


  &__checkout & {
    &__color {
      width: min-content;
      flex-wrap: nowrap;
      justify-content: center;

      span {
        max-width: rem(120);
      }
  }


  &__types {
    flex-direction: row;
    align-items: center;
  }

  &__quantity {
    > span:first-of-type {
      display: none;
    }
  }

  &__bottom {
    margin-bottom: var(--padding-s);

  }

  &__content {
    justify-content: space-between;
  }
  }

    &__types {
      flex-direction: column;
      align-items: flex-start;
      gap: var(--gap-xxs);

      i.icon {
        height: rem(14);
        background-position: center;
      }
    }

    &__bottom {
      align-items: flex-end;
    }



    &__shop-the-look, &__look {
      .row-product-card {
        &__img {
          img {
            width: grid(2);
          }
        }

        &__inner {
        padding: rem(8) 0;
          gap: rem(8);

        }

        &__top {
          gap: rem(3);
        }

        &__title {
          width: grid(3);
        }

        &__content {
          gap: rem(8);
        }

        &__price {
          .price {
            flex-direction: row;
            align-items: flex-start;
            gap: rem(8);
          }
        }

      }
    }

    &__cart {
      .row-product-card {
        &__title {
          max-width: 90%;
        }

        &__color {
          span {
            max-width: 80%;
            @include on-line-text-no-wrap;
          }
        }
      }
    }

  }
}
