.order {
  @extend %typo-base-uppercase;
  min-height: var(--vh);

  /* HEADER */

  &__header {
    @extend .panel__header;
    position: sticky;
    z-index: 9999;
    top: 0;
    width: 100%;
    border-bottom: rem(.5) solid var(--color-grey-divider-light);
    background: var(--color-white);
  }

  &__inner {
    padding: 0 var(--padding-m);
    padding-bottom: rem(105.4);

    &.empty {
      .error-page__wrapper {
        padding-top: var(--padding-m);
      }
      padding: 0;
    }
  }

  &__title {
    padding: var(--padding-m) 0;
    @extend %typo-base-body;
    text-transform: none;
  }

  &__date-price {
    display: flex;
    justify-content: space-between;
    padding: var(--padding-m) 0;
    border-bottom: rem(.5) solid var(--color-grey-divider-light);
    color: var(--color-grey-medium);
  }

  /* BUTTONS */

  &__buttons {
    display: flex;
    margin-bottom: oldGutters(1);
  }

  &__button {
    flex-basis: 100%;
  }

  /* PRODUCTS */

  &__products {
    border-bottom: 1px solid $border-color;

    // .row-product-card__quantity {
    //   span {
    //     padding: 0;
    //   }
    // }

    .row-product-card__quantity {
      height: unset;
      margin-bottom: rem(8);

      span {
        padding: 0;
      }
    }
  }

  &__product {
    .row-product-card__checkout .row-product-card__img img {
      width: rem(88);
    }

    // .row-product-card__checkout .row-product-card__inner {
    //   justify-content: space-between;
    // }

    .row-product-card__checkout .row-product-card__types {
      flex-direction: column;
      align-items: flex-start;
      gap: rem(6);
    }
  }

  /* TOTAL */

  .checkout-total {
    padding-top: var(--padding-m);
    padding-right: 0;
    padding-left: 0;
  }

  .checkout-total__subtotal {
    padding-bottom: var(--padding-m);
    border-bottom: rem(.5) solid var(--color-grey-divider-light);
  }

  .checkout-total__shipping {
    padding-top: var(--padding-m);
    margin-top: 0 !important;
    color: var(--color-black);
  }

  .checkout-total__total {
    align-items: center;
    font-size: rem(12) !important;
  }

  .checkout-total__col {
    align-items: center;

    .checkout-total__subtitle {
      margin-top: 0;
    }
  }

  /* INFO */

  &__infos {
    display: grid;
    padding-top: var(--padding-m);
    gap: var(--padding-m);
    grid-template-columns: repeat(2, 1fr);
  }

  &__info {
    padding: var(--padding-m) var(--padding-m) rem(40) var(--padding-m);
    border: rem(.5) solid var(--color-grey-divider-light);

    &-title {
      margin-bottom: rem(6);
    }

    &-content {
      color: var(--color-grey-medium)
    }

    &-card {
      display: inline-block;
      width: rem(20);
      margin-right: rem(6);
      vertical-align: middle;
    }
  }

  /* FOOTER */

  &__footer {
    position: sticky;
    z-index: 999;
    bottom: 0;
    display: flex;
    width: grid(5, 1);
    padding: var(--padding-m);
    border-top: rem(.5) solid var(--color-grey-divider-light);
    margin-top: var(--padding-m);
    background: var(--color-white);
  }
}

@include tablet-portrait {
  .order {
    width: 100%;

    /* HEADER */

    &__header {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &__title {
      font-size: rem(16);
    }

    .checkout-total__total {
      align-items: center;
      font-size: rem(14) !important;
    }

    // .row-product-card__title {
    //   max-width: 75%;
    // }
    .row-product-card__inner {
      gap: rem(20);
    }

    .row-product-card__content {
      width: 100%;
      max-width: 100%;
    }

    /* INFO */

    &__infos {
      grid-template-columns: repeat(1, 1fr);
    }

    /* FOOTER */

    &__footer {
      width: grid(6, 2);
    }
  }
}
